<template>
  <div :class="[`alert `, alertClass]">
    <strong>{{ title }}!</strong> {{ msg }}
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    title: {
      type: String,
      required: true,
    },
    msg: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    alertClass() {
      return `alert-${this.type}`;
    },
  },
};
</script>

<style>
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
</style>
