<template>
  <div class="container">
    <div>
      <h3>Tem certeza que deseja cancelar seu cadastro?</h3>
    </div>
    <div>
      <p>
        Ao clicar em confirmar, você não irá mais receber e-mails para
        participar de pesquisas.
      </p>
    </div>
    <custom-button
      :loading="isLoading"
      class="mt-10 button"
      icon
      text="Confirmar"
      @click="confirInactiveUser()"
    >
      Não quero mais receber e-mails
      <div slot="icon">
        <span class="material-icons material-icons-outlined">
          arrow_forward
        </span>
      </div>
    </custom-button>
    <Alert
      v-if="errorMessage"
      title="Erro inesperado"
      :msg="errorMessage"
      type="danger"
    />
  </div>
</template>

<script>
import CustomButton from "@/components/Button";
import { api } from "@/api/config";
import Alert from "@/components/Alert";
export default {
  name: "InactiveUser",
  components: { Alert, CustomButton },
  data: () => ({
    isLoading: false,
    errorMessage: null,
  }),
  methods: {
    async confirInactiveUser() {
      this.isLoading = true;

      api({
        url: "/user_status/inactive",
        params: this.$route.query,
        method: "post",
      })
        .then(() => {
          window.location.replace("/");
        })
        .catch(() => {
          this.errorMessage =
            "Não foi possível cancelar seu cadastro, entre em contato com o suporte.";
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.container {
  padding-top: 50px;
  margin: 0 auto;
  text-align: center;
  width: 450px;
}
</style>
